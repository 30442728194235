<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">收益类型</span>
          <el-select class="searchInput" v-model="searchValue.distributionSource" placeholder="请选择收益类型">
            <el-option label="全部" value=""> </el-option>
            <el-option label="团队佣金" :value="1"> </el-option>
            <el-option label="直售佣金" :value="0"> </el-option>
          </el-select>
        </el-col>

        <el-col :span="8">
          <span class="searchText">佣金状态</span>
          <el-select class="searchInput" v-model="searchValue.status" placeholder="请选择佣金状态">
            <el-option label="全部" value=""> </el-option>
            <el-option label="已入账" :value="1"> </el-option>
            <el-option label="待入账" :value="0"> </el-option>
            <el-option label="退款" :value="2"> </el-option>
          </el-select>
        </el-col>

        <el-col :span="8">
          <el-button type="primary" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div class="header">
        <div class="title">数据列表</div>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="skuId" label="SKU ID"> </el-table-column>
        <el-table-column align="center" prop="productName" label="商品信息"> </el-table-column>
        <el-table-column align="center" prop="consumeMemberNickname" label="佣金来源"> </el-table-column>
        <el-table-column align="center" prop="phoneNumber" label="佣金状态">
          <template slot-scope="scope">
            {{ scope.row.distributionStatus | status }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="incomeAmount" label="分销收益"> </el-table-column>
        <el-table-column align="center" label="收益类型">
          <template slot-scope="scope">
            {{ scope.row.distributionSource === 1 ? '团队佣金' : '直售佣金' }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="orderId" label="支付单号"> </el-table-column>

        <el-table-column align="center" label="支付时间">
          <template slot-scope="scope">
            {{ scope.row.orderCreateTime | formatTime }}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchValue: {
        distributionSource: '',
        status: ''
      },
      list: []
    }
  },
  methods: {
    // 请求后端获取分销账单列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          id: this.$route.query.id,
          ...this.searchValue
        }
      }
      this.$api.distribution
        .distribution('distributionMemberBillList', params, this.$route.query.id)
        .then(res => {
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    resetSearch() {
      this.searchValue = {
        distributionSource: '',
        status: ''
      }
      this.getList()
    }
  },
  filters: {
    status(value) {
      if (value === 0) return '待入账'
      if (value === 1) return '已入账'
      return '退款'
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}
</style>
